import Accounting from 'accounting';

export const monthsToYearMonthString = totalMonths => {
  let months = totalMonths % 12;
  let yearsDecimal = totalMonths / 12;
  let years = months > 0 ? yearsDecimal.toFixed(1) : yearsDecimal;

  let timeString = '';
  if (years >= 1) {
    timeString += years + ' year';
    if (years > 1) {
      timeString += 's';
    }
  } else {
    if (months > 0) {
      timeString += months + ' months';
    }
  }
  return timeString;
};

export const formatAsCurrency = num => {
  if (isNaN(num) || num === Infinity || !num) {
    return '';
  } else {
    return Accounting.formatMoney(num, '$', 0);
  }
};

/**
 * roundToPrecision
 *
 * @param {number} num - float number to be rounded
 * @param {number} precision - float precision to round to
 * @returns {number}
 */
export const roundToPrecision = (num = 0, precision = 0) => {
  const multiplicator = Math.pow(10, precision);
  const tempNum = parseFloat((num * multiplicator).toFixed(11));
  return Math.round(tempNum) / multiplicator;
};

/**
 * Get a URL parameter by name
 * https://stackoverflow.com/a/5158301/10557
 */
export const geURLParameterByName = (search, name) => {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

// converts absolute URL string to relative URL, useful for staging enviroment
export const toRelativeLink = linkString =>
  // eslint-disable-next-line no-useless-escape
  linkString.replace(/^(?:\/\/|[^\/]+)*\//, '');

// converts snake_case or kebab-case string to camelCase
export const toCamelCase = string =>
  // eslint-disable-next-line flowtype/require-parameter-type
  string.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
